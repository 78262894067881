<template>
    <b-container>
        <b-row class="justify-content-center pt-2" style="background-color: #f6f7fa;">
            <b-col id="contentPrintSubscription" ref="contentPrintSubscription" cols="5" md="10">
                <b-container class="h-100 bg-wave">
                    <div class="pt-5 px-3 pb-3">
                        <b-row>
                            <b-col cols="4">
                                <b-img :src="require('../../src/assets/images/logo/logo-invoice.png')" alt="invoice"
                                    style="width: 300px;" />
                            </b-col>
                            <b-col cols="8">
                                <h1 class="text-uppercase fw-bold-900" style="color: #52CEC2;">
                                    PT. Dayacipta Solusi Indonesia
                                </h1>
                                <div style="color: #52CEC2;">
                                    Gedung Menara Satu Lt.7, Unit 0701, Jl. Boulevard Raya Blok LA 3, No.1,<br>
                                    Kelapa Gading, Jakarta Utara 14240
                                </div>
                            </b-col>
                        </b-row>
                        <h1 class="text-uppercase fw-bold-600 mt-5" style="color: #28C76F">
                            Invoice
                        </h1>
                        <b-row>
                            <b-col cols="6">
                                {{ userData.name_in_invoice }}
                            </b-col>
                            <b-col cols="6">
                                {{ detailSubscription.invoice_number }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                {{ userData.address }}
                            </b-col>
                            <b-col cols="6">
                                Payment date: {{ detailSubscription.payment_created_at }}
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mt-1 ml-0" style="margin-right: 6rem;">
                        <b-col lg="12" class="pl-0">
                            <table class="table table-hover mb-0">
                                <thead>
                                    <tr class="border-bottom"
                                        style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                        <!-- <th scope="col" class="text-left"
                                            style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;width:16rem">
                                            #
                                        </th> -->
                                        <th scope="col" class="text-left"
                                            style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;width:16rem">
                                            Description
                                        </th>
                                        <th scope="col" class="text-right"
                                            style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                            Quantity
                                        </th>
                                        <th scope="col" class="text-right"
                                            style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                            Price
                                        </th>
                                        <th scope="col" class="text-right"
                                            style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!-- <td class="border-0 pb-0 pt-1 text-left">
                                            1
                                        </td> -->
                                        <td class="border-0 pb-0 pt-1 text-left">
                                            {{ detailSubscription.subscription_renewal.renewal_name }}
                                        </td>
                                        <td class="border-0 pb-0 pt-1 text-right">
                                            1
                                        </td>
                                        <td class="border-0 pb-0 pt-1 text-right">
                                            {{ detailSubscription.subscription_renewal.sell_price | formatInvoice }}
                                        </td>
                                        <td class="border-0 pb-0 pt-1 text-right">
                                            {{ detailSubscription.subscription_renewal.sell_price | formatInvoice }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr class="my-1" style="border-top: 1px solid #000;">
                            <b-row align-h="end">
                                <b-col cols="5" style="padding-right: 24px;">
                                    <div class="d-flex justify-content-between">
                                        <h6 class="text-black size16 mb-0 fw-bold-700">
                                            Sub Total
                                        </h6>
                                        <h6 class="text-black size16 mb-0 fw-bold-700">
                                            {{ detailSubscription.subscription_renewal.sell_price | formatAmount }}
                                        </h6>
                                    </div>
                                    <hr class="my-1" style="border-top: 1px solid #000;">
                                    <div class="d-flex justify-content-between mb-3">
                                        <h6 class="text-black size16 mb-0 fw-bold-700">
                                            Total Due
                                        </h6>
                                        <h6 class="text-black size16 mb-0 fw-bold-700">
                                            {{ detailSubscription.subscription_renewal.sell_price | formatAmount }}
                                        </h6>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>
  
<script>
import {
    BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BContainer,
        BImg
    },
    props: {
        detailSubscription: {
            type: Object,
        },
    },
    data() {
        return {
            fields: [],
            items: [],
            supplier: {},
            userData: JSON.parse(localStorage.getItem('userData')).merchant,
        }
    },
    watch: {
    },
    mounted() {
    },
}
</script>  

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';


.bg-wave {
    background-color: #FFFFFF;
    border-radius: 24px;
}
</style>
<template>
    <div class="text-dark">
        <b-container fluid>
            <div
                v-if="detailSubscription && detailSubscription.payment_method === 'bca_va' && detailSubscription.payment_status === 'pending'">
                <b-row align-h="center">
                    <b-col xl="5" lg="6" md="8" sm="9" xs="12">
                        <b-row class="mb-1">
                            <b-col class="size24 fw-bold-800">
                                Detail Transaksi
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                ID Transaksi
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.invoice_number }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Status
                            </b-col>
                            <b-col class="text-right">
                                <span class="bg-warning p-1 rounded-pill fw-bold-700">
                                    {{ detailSubscription.payment_status === 'pending' ? 'Menunggu Pembayaran' : '' }}
                                </span>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Paket Berlangganan
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.renewal_name }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Nominal
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.sell_price | formatAmount }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Metode Pembayaran
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.payment_method === 'bca_va' ? 'BCA VA - ' : '' }}
                                {{ detailSubscription.va_account_number }}
                                <feather-icon icon="CopyIcon" class="text-primary cursor-pointer" size="16"
                                    @click="copyToClipboard(detailSubscription.va_account_number)" />
                                <div v-if="copied" class="badge badge-light-success">Berhasil disalin!</div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Waktu Transaksi
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.payment_created_at }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-5">
                            <b-col>
                                Bayar Sebelum
                            </b-col>
                            <b-col class="text-right text-danger">
                                {{ detailSubscription.payment_expired_at }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col class="size24 fw-bold-800">
                                Cara Bayar
                            </b-col>
                        </b-row>
                        <div class="card p-1" v-b-toggle.collapse-1 variant="primary">
                            <div class="d-flex">
                                <b-img :src="require('/src/assets/images/logo/logo-bca.png')" alt="bca"
                                    style="width: 4rem;" />
                                <span class="ml-1">
                                    Bank Central Asia - Virtual Account
                                </span>
                                <span class="ml-auto">
                                    <feather-icon icon="ChevronDownIcon" size="16" />
                                </span>
                            </div>
                            <b-collapse id="collapse-1">
                                <hr />
                                <div class="size16 fw-bold-900 ml-1">Melalui ATM</div>
                                <ol>
                                    <li>Masukkan kartu ke mesin ATM</li>
                                    <li>Masukkan 6 digit PIN Anda</li>
                                    <li>Pilih “Transaksi Lainnya”</li>
                                    <li>Pilih “Transfer”</li>
                                    <li>Lanjut ke “ke Rekening BCA Virtual Account”</li>
                                    <li>Masukkan nomor BCA Virtual Account Anda, kemudian tekan “Benar”</li>
                                    <li>Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”</li>
                                    <li>Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah
                                        benar, kemudian pilih “Ya”</li>
                                    <li>Pembayaran Anda telah selesai. Tekan “Tidak” untuk menyelesaikan transaksi, atau
                                        tekan “Ya” untuk melakukan transaksi lainnya</li>
                                </ol>
                                <div class="size16 fw-bold-900 ml-1">Melalui Aplikasi BCA Mobile</div>
                                <ol>
                                    <li>Silahkan login pada aplikasi BCA Mobile</li>
                                    <li>Pilih “m-BCA”, lalu masukkan kode akses m-BCA</li>
                                    <li>Pilih “m-Transfer”</li>
                                    <li>Lanjut ke “BCA Virtual Account”</li>
                                    <li>Masukkan nomor BCA Virtual Account Anda, atau pilih dari Daftar Transfer</li>
                                    <li>Lalu, masukkan jumlah yang akan dibayarkan</li>
                                    <li>Masukkan PIN m-BCA Anda</li>
                                    <li>Transaksi telah berhasil</li>
                                </ol>
                            </b-collapse>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div
                v-else-if="detailSubscription && detailSubscription.payment_method === 'bca_va' && detailSubscription.payment_status === 'success'">
                <b-row align-h="center">
                    <b-col xl="5" lg="6" md="8" sm="9" xs="12">
                        <b-row class="mb-2">
                        </b-row>
                        <b-row class="mb-1">
                            <b-col class="size24 fw-bold-800">
                                Detail Berlangganan
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                ID Transaksi
                            </b-col>
                            <b-col class="text-right" @click="print">
                                <b-button class="bg-white border-8">
                                    {{ detailSubscription.invoice_number }}
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Status
                            </b-col>
                            <b-col class="text-right">
                                <span class="bg-primary p-1 rounded-pill fw-bold-700">
                                    {{ detailSubscription.payment_status === 'success' ? 'Aktif' : '' }}
                                </span>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Paket Berlangganan
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.renewal_name }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Nominal
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.sell_price | formatAmount }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-5">
                            <b-col>
                                Berlaku Hingga
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.payment_expired_at }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div
                v-else-if="detailSubscription && detailSubscription.payment_method === 'bca_va' && detailSubscription.payment_status === 'canceled'">
                <b-row align-h="center">
                    <b-col xl="5" lg="6" md="8" sm="9" xs="12">
                        <b-row class="mb-2">
                        </b-row>
                        <b-row class="mb-1">
                            <b-col class="size24 fw-bold-800">
                                Detail Berlangganan
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                ID Transaksi
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.invoice_number }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Status
                            </b-col>
                            <b-col class="text-right">
                                <span class="bg-danger p-1 rounded-pill fw-bold-700 text-white">
                                    {{ detailSubscription.payment_status === 'canceled' ? 'Dibatalkan' : '' }}
                                </span>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Paket Berlangganan
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.renewal_name }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Nominal
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.sell_price | formatAmount }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-5">
                            <b-col>
                                Berlaku Hingga
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.payment_expired_at }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div
                v-else-if="detailSubscription && detailSubscription.payment_method === 'bca_va' && detailSubscription.payment_status === 'expired'">
                <b-row align-h="center">
                    <b-col xl="5" lg="6" md="8" sm="9" xs="12">
                        <b-row class="mb-2">
                        </b-row>
                        <b-row class="mb-1">
                            <b-col class="size24 fw-bold-800">
                                Detail Berlangganan
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                ID Transaksi
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.invoice_number }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Status
                            </b-col>
                            <b-col class="text-right">
                                <span class="secondary-2 p-1 rounded-pill fw-bold-700 text-white">
                                    {{ detailSubscription.payment_status === 'expired' ? 'Kedaluwarsa' : '' }}
                                </span>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Paket Berlangganan
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.renewal_name }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                Nominal
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.subscription_renewal.sell_price | formatAmount }}
                            </b-col>
                        </b-row>
                        <b-row class="mb-5">
                            <b-col>
                                Berlaku Hingga
                            </b-col>
                            <b-col class="text-right">
                                {{ detailSubscription.payment_expired_at }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-container>
        <InvoiceSubscription :detailSubscription="detailSubscription" class="d-none" />
    </div>
</template>

<script>
import {
    BModal, BContainer, BRow, BCol, BFormGroup, BFormInput, BImg, BCard, BButton, BFormCheckbox, BFormRadio, BCollapse, VBToggle
} from 'bootstrap-vue'

import ModalHeader from '@/components/ModalHeader.vue';
import InvoiceSubscription from '@/components/InvoiceSubscription.vue';

export default {
    components: {
        ModalHeader,
        BModal,
        BContainer,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BImg,
        BCard,
        BButton,
        BFormCheckbox,
        BFormRadio,
        BCollapse,
        InvoiceSubscription
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            copied: false,
        }
    },
    props: {
        detailSubscription: Object,
    },
    methods: {
        copyToClipboard(text) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 1500);
        },
        async print() {
            const { hostname, protocol, port } = window.location

            const noLandscapeOptions = {
                name: '_blank',
                specs: [
                    'fullscreen=yes',
                    'titlebar=yes',
                    'scrollbars=yes',
                ],
                styles: [
                    `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/bootstrap.css`,
                ],
                timeout: 1000,
                autoClose: true,
                windowTitle: window.document.title,
            };

            await this.$htmlToPaper('contentPrintSubscription', noLandscapeOptions);
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.secondary-2 {
    background-color: $secondary-2;
}
</style>